import { format } from "date-fns";
import { FC, useCallback, useState } from "react";
import { useLocation } from "react-router-dom";

import { Button, GuestInRateType, Picture, RatesToBlockType, Tag } from "@src/components";
import { Rates } from "@src/components/ChooseRoom/ChooseRoomsCard/Rates";
import { RatesMobile } from "@src/components/ChooseRoom/RatesMobile";
import { RatesModal } from "@src/components/ChooseRoom/RatesModal";
import { MAIN_DATE_FORMAT } from "@src/constants";
import { useActions, useAppSelector, useGetBaseData } from "@src/hooks";
import { IRate, Rooms } from "@src/interfaces";
import { getDates } from "@src/store/slices/datepicker/datepicker.selector";
import { declOfNum, handleObjEqualCheck } from "@src/utils";

import { MobileSelectedTariff } from "./MobileSelectedTariff";
import { Styled } from "./styles";
import { useChangeSearchParams } from "@src/hooks/useChangeSearchParams";

type ChooseRoomsCardProps = {
  room: Rooms;
  allRooms: Rooms[];
  onSetRateCounter: (rate: IRate, id: string, price: string, isCancel: boolean) => void;
  rateCounter: { [key: string]: number };
  isAllSelected: boolean;
  setOpenYourChoice: () => void;
  selectedRooms: IRate[];
  onDeleteSelectedRateClick: (room_name: string) => void;
  bookingDate: string;
  allRates: number;
  totalPrice: number;
  allGuests: number;
  onDeleteRoomClick?: (priceId: string, priceIndex: number) => void;
  onMobileDeleteRoomClick?: (priceId: string) => void;
  ratesToBlock: RatesToBlockType[];
  roomId: number;
};

const ChooseRoomsCard: FC<ChooseRoomsCardProps> = ({
  setOpenYourChoice,
  isAllSelected,
  allGuests,
  totalPrice,
  bookingDate,
  allRates,
  room,
  onSetRateCounter,
  selectedRooms,
  rateCounter,
  allRooms,
  onDeleteSelectedRateClick,
  onDeleteRoomClick,
  onMobileDeleteRoomClick,
  ratesToBlock,
  roomId,
}) => {
  const { id, img, name, description, fill_values: tags, rates, total_room_available } = room;
  const [numberOfSelectedRooms, setNumberOfSelectedRooms] = useState(0);
  const hasFreeRooms = numberOfSelectedRooms <= Number(total_room_available);

  const MAX_AMOUNT_OF_ROOMS_TO_SHOW = 3;
  const showNumberOfRooms = Number(total_room_available) <= MAX_AMOUNT_OF_ROOMS_TO_SHOW;
  const countNumberOfSelectedRooms = (value: number) => {
    setNumberOfSelectedRooms((prev) => {
      return (prev = value);
    });
  };

  const {
    bookingText,
    cancelButton,
    textNight,
    textNights,
    textNightsGenetiv,
    moreText,
    roomText,
    roomsText,
    roomsTextGenetiv,
    currencyValue,
    textFrom,
    bookingChoosePlanText,
  } = useGetBaseData();
  const { range } = useAppSelector((state) => state.datepicker);
  const { arrivalDateValue, departureDateValue } = useAppSelector(getDates);
  const { tariffCounter, roomCounter } = useAppSelector((state) => state.bookingData);
  const { setRoomCounter } = useActions();
  const [openRatesModal, setOpenRatesModal] = useState(false);
  const [isHidden, setIsHidden] = useState(false);
  const endDate = range.endDate ? format(range.endDate, MAIN_DATE_FORMAT) : "";
  const startDate = range.startDate ? format(range.startDate, MAIN_DATE_FORMAT) : "";
  const currentRoom = selectedRooms.filter((r) => r.room_name === name);
  const isSelected = !!currentRoom.length;

  const minPriceArray: number[] = allRooms
    .filter((r) => r.name === name)
    .flatMap((r) => r.rates)
    .flatMap((r) => r?.prices)
    .map((r) => +r.total);
  const minPrice = Math.min(...minPriceArray);

  const shortDepartureDateValue = departureDateValue
    ? `${endDate.split(" ")[0]} ${endDate?.split(" ")[1]?.slice(0, 3)}.`
    : "";
  const shortArrivalDateValue = arrivalDateValue
    ? `${startDate.split(" ")[0]} ${startDate?.split(" ")[1]?.slice(0, 3)}.`
    : "";

  const timeDiff = Math.abs(new Date(departureDateValue).getTime() - new Date(arrivalDateValue).getTime());
  const numberOfNights = Math.ceil(timeDiff / (1000 * 3600 * 24));
  const nightsText = `${numberOfNights} ${declOfNum(numberOfNights, [textNight, textNights, textNightsGenetiv])}`;

  const dateText = `${nightsText} ${shortArrivalDateValue} - ${shortDepartureDateValue}`;

  const ROOMS_DECL = [roomText, roomsText, roomsTextGenetiv];

  const location = useLocation();
  const locationToCheck = location.pathname.split("/");
  const isEnglishVersion = locationToCheck.indexOf("en") > 0;
  const leftWord = isEnglishVersion ? "left" : "";

  const formatedPrice = String(minPrice)
    .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    .split(".")[0];

  const resetNumberOfCurrentRoom = () => {
    setRoomCounter({ ...roomCounter, [id]: 0 });
  };
  const handleDeleteMobileItems = () => {
    resetNumberOfCurrentRoom();
    onDeleteSelectedRateClick(name);
  };

  const handleCloseModal = useCallback(() => {
    setOpenRatesModal(false);
  }, []);

  // todo room preview
  const { setQueryParams } = useChangeSearchParams();
  const handleRoomPreviewClick = () => {
    setQueryParams(roomId, false, true);
  };

  return (
    <Styled.ChooseRoomsCardContainer>
      <RatesModal
        title={bookingChoosePlanText}
        isOpen={openRatesModal}
        onModalCloseClick={() => {
          setOpenRatesModal(false);
          setOpenYourChoice();
        }}>
        <RatesMobile
          allGuests={allGuests}
          totalPrice={totalPrice}
          allRates={allRates}
          room={room}
          onSetRateCounter={onSetRateCounter}
          rateCounter={rateCounter}
          isAllSelected={isAllSelected}
          numberOfNights={numberOfNights}
          totalRoomAvailable={total_room_available}
          roomId={id}
          handleRatesModalClose={handleCloseModal}
          setOpenYourChoice={setOpenYourChoice}
        />
      </RatesModal>
      <Styled.ChooseRoomsCard>
        {total_room_available && showNumberOfRooms && (
          <Styled.TotalAmount>
            {total_room_available} {declOfNum(+total_room_available, ROOMS_DECL)} {leftWord}
          </Styled.TotalAmount>
        )}

        <Styled.ImgContainer onClick={handleRoomPreviewClick}>
          <Picture img={img} smallGallery />
        </Styled.ImgContainer>
        <Styled.ImgContainerMobile onClick={handleRoomPreviewClick}>
          <Picture img={img} />
        </Styled.ImgContainerMobile>
        <Styled.DescriptionWrapper>
          <div>
            <Styled.Name onClick={handleRoomPreviewClick}>{name}</Styled.Name>
            <Styled.Description isHidden={isHidden}>
              {description}
              {!isHidden && description && description?.length > 162 && (
                <Styled.MoreButton>
                  {`...`}
                  <p onClick={() => setIsHidden((prev) => !prev)}>{moreText.toLowerCase()}</p>
                </Styled.MoreButton>
              )}
            </Styled.Description>
          </div>
          <Styled.Tags>
            {tags?.map((tag) => (
              <Tag key={tag.id} {...tag} />
            ))}
          </Styled.Tags>
        </Styled.DescriptionWrapper>
      </Styled.ChooseRoomsCard>
      <Styled.RatesWrapper>
        {rates?.map((rate, index) => (
          <Rates
            numberOfNights={numberOfNights}
            allRates={allRates}
            isAllSelected={isAllSelected}
            rate={rate}
            key={index}
            index={index}
            onSetRateCounter={onSetRateCounter}
            rateCounter={rateCounter}
            hasFreeRooms={hasFreeRooms}
            numberOfSelectedRooms={numberOfSelectedRooms}
            countNumberOfSelectedRooms={countNumberOfSelectedRooms}
            totalRoomAvailable={total_room_available}
            roomId={id}
            ratesToBlock={ratesToBlock}
          />
        ))}
      </Styled.RatesWrapper>
      <Styled.MobileFooter>
        <Styled.PriceWrapper>
          <Styled.Price>
            {textFrom} {formatedPrice} {currencyValue}
          </Styled.Price>
          <Styled.SubTitle>{dateText}</Styled.SubTitle>
        </Styled.PriceWrapper>
        <Button
          isDisabled={isAllSelected && !isSelected}
          primary={(!isAllSelected && isSelected) || !isAllSelected}
          outline={isSelected}
          title={isAllSelected && isSelected ? cancelButton : bookingText}
          onClick={() => {
            isAllSelected && isSelected ? handleDeleteMobileItems() : setOpenRatesModal(true);
          }}
        />

        <Styled.SelectedRoomsContainer>
          {currentRoom.map((room, index) => {
            return (
              <MobileSelectedTariff
                room={room}
                index={index}
                priceId={room.priceId}
                roomId={id}
                currentRoom={currentRoom}
                title={room.name}
                onMobileDeleteRoomClick={onMobileDeleteRoomClick}
                key={`${room.id}/${index}`}
              />
            );
          })}
        </Styled.SelectedRoomsContainer>
      </Styled.MobileFooter>
    </Styled.ChooseRoomsCardContainer>
  );
};

export default ChooseRoomsCard;
