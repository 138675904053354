import { createGlobalStyle } from "styled-components";

import { theme } from "@src/theme";

import { font, width100 } from "./mixins";

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: NunitoSans;
    src: url('../assets/fonts/NunitoSans-Black.woff2') format('woff2'),
    url('../assets/fonts/NunitoSans-Black.woff') format('woff');
    font-weight: 900;
    font-display: swap;
  }

  @font-face {
    font-family: NunitoSans;
    src: url('../assets/fonts/NunitoSans-Bold.woff2') format('woff2'),
    url('../assets/fonts/NunitoSans-Bold.woff') format('woff');
    font-weight: 700;
     font-display: swap;
  }

  @font-face {
    font-family: NunitoSans;
    src: url('../assets/fonts/NunitoSans-SemiBold.woff2') format('woff2'),
    url('../assets/fonts/NunitoSans-SemiBold.woff') format('woff');
    font-weight: 600;
     font-display: swap;
  }

  @font-face {
    font-family: NunitoSans;
    src: url('../assets/fonts/NunitoSans-Regular.woff2') format('woff2'),
    url('../assets/fonts/NunitoSans-Regular.woff') format('woff');
    font-weight: 400;
     font-display: swap;
  }

  @font-face {
    font-family: Montserrat;
    src: url('../assets/fonts/Montserrat-SemiBold.woff2') format('woff2'),
    url('../assets/fonts/Montserrat-SemiBold.woff') format('woff');
    font-weight: 600;
     font-display: swap;
  }

  @font-face {
    font-family: Montserrat;
    src: url('../assets/fonts/Montserrat-Medium.woff2') format('woff2'),
    url('../assets/fonts/Montserrat-Medium.woff') format('woff');
    font-weight: 500;
     font-display: swap;
  }

  @font-face {
    font-family: Montserrat;
    src: url('../assets/fonts/Montserrat-Regular.woff2') format('woff2'),
    url('../assets/fonts/Montserrat-Regular.woff') format('woff');
    font-weight: 400;
     font-display: swap;
  }


  body {
    font-family: NunitoSans, sans-serif;
    ${font({ size: "16", lineHeight: "22" })}
    overflow-x: hidden;

    &.hidden {
      overflow: hidden;
    }
  }

  button {
    padding: 0;
    margin: 0;
    border: none;
    background-color: transparent;
    cursor: pointer;

    &:active,
    &:focus {
      border: none;
      outline: none;
    }
  }

  img {
    ${width100}
  }

  svg:focus {
      border: none;
      outline: none;
    }

  h1, h2, h3, h4, h5, h6, p {
    margin: 0;
  }

  .ReactModal__Body--open {
    overflow: hidden;
  }

  .ReactModal__Content {
    &.datepickerModal {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      max-width: 1028px;
      max-height: 100vh;
      padding: 28px 48px 48px;
      transform: translate(-50%, -50%);
      background-color: ${theme.color.white};
      box-sizing: border-box;

      &:focus {
        outline: none;
      }

      @media screen and (max-width: ${theme.breakpoints.large}) {
        width: 100%;
        padding: 0;
        height: 80%;
        top: auto;
        left: auto;
        bottom: 0;
        transform: translate(0);
        border-radius: 20px 20px 0 0;
        overflow: hidden;
      }
    }



  }



      input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


input[type=number]::-moz-number-spin-box {
    -moz-appearance: none;
    margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}
`;
