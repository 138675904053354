import styled, { css } from "styled-components";

import { box, font } from "@src/styles/mixins";

const SelectContainer = styled.div<{ hasError?: boolean }>`
  position: relative;
  .form-select {
    &__control {
      background-color: ${({ theme }) => theme.color.white};

      border: 1px solid ${({ theme }) => theme.color.greyBorder};
      border-radius: 0;

      ${(props) =>
        props.hasError &&
        css`
          border: 1px solid ${({ theme }) => theme.color.red};
        `}

      &--menu-is-open {
        border-color: ${({ theme }) => theme.color.greyBorder};
        box-shadow: none;

        .form-select__indicator {
          transform: rotate(180deg);
        }
      }
      &--menu-is-focused {
        border-color: ${({ theme }) => theme.color.greyBorder};
        box-shadow: none;
      }

      &:hover {
        border-color: ${({ theme }) => theme.color.greyBorder};

        ${(props) =>
          props.hasError &&
          css`
            border: 1px solid ${({ theme }) => theme.color.red};
          `}
      }
    }

    &__placeholder {
      margin: 0;
      padding: 0;

      ${font({ size: "16", lineHeight: "24", fontWeight: "400" })}

      color: ${({ theme }) => theme.color.black};
    }

    &__value-container {
      padding: 20px !important;
      cursor: text !important;
    }

    &__indicator-separator {
      display: none !important;
    }

    &__indicators {
      cursor: pointer !important;
    }

    &__indicator {
      color: ${({ theme }) => theme.color.greyBg} !important;
      margin-right: 14px;

      & svg {
      }
    }

    &__menu {
      margin: 0;
      border: 1px solid ${({ theme }) => theme.color.lightGreyBorder};
      border-top-width: 0;
      border-radius: 0;
      box-shadow: none;
    }

    &__menu-list {
      padding: 0;
    }

    &__option {
      cursor: pointer !important;

      padding: 8px 18px;

      font-weight: 400;
      font-size: 16px;
      line-height: 24px;

      color: #262324;

      &--is-selected {
        color: ${({ theme }) => theme.color.red};
        background-color: unset;
      }
      &--is-focused {
        color: ${({ theme }) => theme.color.grey};
        background-color: unset;
      }
    }

    &-container {
      > div {
        outline: none !important;
        box-shadow: none !important;
      }

      .form-control__control {
        border-color: #ced4da !important;
        box-shadow: none !important;
      }

      &:hover,
      &--menu-is-open {
        border-color: #ced4da !important;
      }

      .form-control__indicators {
      }
    }

    &__menu {
    }
  }
`;

const Icon = styled.img`
  ${box(24)};
  cursor: pointer;
  position: absolute;
  right: 56px;
  top: 21px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    right: 56px;
    top: 21px;
  }
`;

const ErrorText = styled.span`
  ${font({ size: "13", lineHeight: "18", fontWeight: "400" })}
  color: ${({ theme }) => theme.color.red};
`;

export const Styled = {
  SelectContainer,
  ErrorText,
  Icon,
};
