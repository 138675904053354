import { FC, ReactNode } from "react";
import Div100vh from "react-div-100vh";
import Modal from "react-modal";

import { Button } from "@src/components";
import { popupStylesWithFullWIdthContent } from "@src/styles/popupStyles";

import { Styled } from "./styles";

export interface ModalProps extends Modal.Props {
  children?: ReactNode;
  title?: string;
  withHeader?: boolean;
  withButton?: boolean;
  snowCloseButton?: boolean;
  onModalCloseClick: () => void;
  onSubmitClick?: () => void;
  closeIconStroke?: string;
  size?: "medium" | "large";
  buttonTitle?: string;
  buttonType?: "submit" | "reset" | "button";
  hasFixedFooter?: boolean;
  isCloseBtnSticky?: boolean;
  tabletFullWitdh?: boolean;
}

export const ModalCustom: FC<ModalProps> = ({
  onModalCloseClick,
  onSubmitClick,
  withButton,
  closeIconStroke,
  withHeader,
  children,
  title,
  size = "medium",
  buttonTitle,
  buttonType = "button",
  snowCloseButton = true,
  hasFixedFooter,
  isCloseBtnSticky = false,
  tabletFullWitdh,
  ...props
}) => {
  return (
    <Modal
      {...props}
      style={{
        ...popupStylesWithFullWIdthContent,
        overlay: {
          display: "grid",
          placeItems: "center",
          ...popupStylesWithFullWIdthContent.overlay,
        },
      }}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      onRequestClose={onModalCloseClick}>
      <Div100vh>
        <Styled.ModalContent size={size} tabletFullWitdh={tabletFullWitdh}>
          {withHeader && (
            <Styled.ModalHeader>
              {title}
              {snowCloseButton && (
                <Styled.CloseButton
                  onClick={onModalCloseClick}
                  stroke={closeIconStroke}
                  isCloseBtnSticky={isCloseBtnSticky}
                  tabletFullWitdh
                />
              )}
            </Styled.ModalHeader>
          )}
          <Styled.ModalBody hasFixedFooter={hasFixedFooter}>{children}</Styled.ModalBody>
          {withButton && (
            <Styled.ButtonSubmit>
              <Button type={buttonType} mt="32px" title={buttonTitle} primary onClick={onSubmitClick} />
            </Styled.ButtonSubmit>
          )}
        </Styled.ModalContent>
      </Div100vh>
    </Modal>
  );
};

ModalCustom.defaultProps = {
  closeIconStroke: "grey",
};
