import { Footer } from "@src/components/Footer";
import { SpaHeroSection } from "@src/components/Spa/SpaHeroSection";
import { useGetMetaData } from "@src/hooks/useGetMetaData";
import { FC } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { SpaAbout } from "@src/components/Spa/SpaAbout";
import { SpaInfrastructure } from "@src/components/Spa/SpaInfrastructure";
import { SpaServices } from "@src/components/Spa/SpaServices";
import { SpaMenu } from "@src/components/Spa/SpaMenu";
import { CenterBannerSlider, Contacts } from "@src/components";
import { useGetSpaPromoBannersQuery } from "@src/store/services";
import { useGetBaseData } from "@src/hooks";

const Spa: FC = () => {
  const location = useLocation();
  let currentLocation = location.pathname;
  if (currentLocation.includes("/en")) {
    currentLocation = currentLocation.substring(3);
  }

  const { metaTitle, metaDescription, metaKeywords } = useGetMetaData(currentLocation);
  const { spaPromoTitle } = useGetBaseData();

  const { data: bannersData, isLoading: isBannerLoading } = useGetSpaPromoBannersQuery();

  return (
    <>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta name="keywords" content={metaKeywords} />
      </Helmet>
      <SpaHeroSection />
      <SpaAbout />
      <SpaInfrastructure />
      <SpaMenu />

      <SpaServices />
      {bannersData && !isBannerLoading && <CenterBannerSlider data={bannersData} isSpaPage title={spaPromoTitle} />}
      <Contacts isSpaPage />
      <Footer noMargin />
    </>
  );
};

export default Spa;
