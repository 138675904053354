import styled, { css } from "styled-components";

import { box, font } from "@src/styles/mixins";

const BreadcrumbsContainer = styled.div`
  display: flex;
  align-items: center;

  & a {
    display: inline-flex;
    align-items: center;
    text-decoration: none;

    ${font({ size: "16", lineHeight: "24", fontWeight: "400" })};
    color: ${({ theme }) => theme.color.grey};

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
      ${font({ size: "13", lineHeight: "20", fontWeight: "400" })};
    }
  }
`;

const BreadcrumbsDivider = styled.span`
  display: inline-block;
  ${box(4)}
  margin: 0 16px;

  ${font({ size: "16", lineHeight: "24", fontWeight: "400" })};
  background-color: ${({ theme }) => theme.color.greyBg};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin: 0 12px;
  }
`;

const BreadcrumbUnactiveLink = styled.span`
  display: inline-flex;
  align-items: center;
  text-decoration: none;

  ${font({ size: "16", lineHeight: "24", fontWeight: "400" })};
  color: ${({ theme }) => theme.color.grey};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    ${font({ size: "13", lineHeight: "20", fontWeight: "400" })};
  }
`;

const Breadcrumbs = styled.div<{ isEventPage?: boolean; isDetailedPage?: boolean }>`
  display: flex;
  align-items: flex-start;
  max-width: 1400px;
  width: calc(100% - 40px);
  margin: 0 auto;
  padding: 64px 0px 4px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xlarge}) {
    max-width: 1184px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletLarge}) {
    padding: 20px 0px 4px;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}) {
    padding: 64px 0px 4px;
  }

  ${(props) =>
    props.isEventPage &&
    css`
      width: calc(100% - 40px);
      max-width: 1400px;
      margin: 0 auto;
      padding: 20px 0px 4px;

      @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
        width: calc(100% - 40px);
        padding: 20px 0px 4px;
        margin: 0 auto;
      }

      @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}) {
        width: calc(100% - 48px);
      }
      @media screen and (min-width: ${({ theme }) => theme.breakpoints.tabletLarge}) {
        padding: 64px 0px 4px;
      }
    `}

  ${(props) =>
    props.isDetailedPage &&
    css`
      position: relative;
      z-index: 2;

      a,
      ${BreadcrumbUnactiveLink} {
        color: ${({ theme }) => theme.color.white};
      }

      ${BreadcrumbsDivider} {
        background-color: ${({ theme }) => theme.color.white};
      }
    `}
`;

export const Styled = { Breadcrumbs, BreadcrumbsContainer, BreadcrumbsDivider, BreadcrumbUnactiveLink };
