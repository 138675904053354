import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Route, Routes, useLocation } from "react-router-dom";

import { Breadcrumbs, ReservationGuestsCounter, BookedMobile, BookingOk } from "@src/components";
import { BookingCancellation } from "@src/components/BookingCancellation";
import { Footer } from "@src/components/Footer";
import { ReservationForm } from "@src/components/ReservationForm";
import { ROUTES } from "@src/constants";
import { useActions, useGetBaseData } from "@src/hooks";
import { useGetMetaData } from "@src/hooks/useGetMetaData";

import { ChooseRoom } from "../../components/ChooseRoom";
import { Styled } from "./styles";

const Reservation = () => {
  const location = useLocation();
  const isCancellationPage = location.pathname.includes("/cancellation");

  const { metaTitle, metaDescription, metaKeywords } = useGetMetaData("/");
  const { bookingTitle, bookingCancelHeader } = useGetBaseData();

  const pageTitle = !isCancellationPage ? bookingTitle : bookingCancelHeader;

  const { setFirstScreenIsView, setIsStatusMessageOnTop } = useActions();
  const [isHeaderSticky, setIsHeaderSticky] = useState(false);

  const OFFSET_TOP_FOR_STICKY_HEADER = 30;

  const handlePageScroll = () => {
    const isScrolled = window.scrollY > OFFSET_TOP_FOR_STICKY_HEADER;
    setIsHeaderSticky(window.scrollY > 0);
    setIsStatusMessageOnTop(window.scrollY > 0);
    setFirstScreenIsView(!isScrolled);
  };

  useEffect(() => {
    window.addEventListener("scroll", handlePageScroll);
    return () => {
      window.removeEventListener("scroll", handlePageScroll);
    };
  }, []);

  const FULL_HEADER_HEIGHT = 180;
  const STICKY_HEADER_HEIGHT = 97;
  const headerHeight = !isHeaderSticky ? FULL_HEADER_HEIGHT : STICKY_HEADER_HEIGHT;

  return (
    <Styled.ReservationContainer headerHeight={headerHeight}>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta name="keywords" content={metaKeywords} />
      </Helmet>
      <Breadcrumbs />
      <Styled.Header>{pageTitle}</Styled.Header>
      <Styled.ReservationContent>
        <Routes>
          <Route path={`${ROUTES.GUESTS_COUNTER}`} element={<ReservationGuestsCounter />} />
          <Route path={`${ROUTES.SELECT_ROOM}`} element={<ChooseRoom />} />
          <Route path={`${ROUTES.RESERVATION_PAY}`} element={<ReservationForm />} />
          <Route path={`${ROUTES.BOOKED}/:kod`} element={<BookingOk />} />
          <Route path={`${ROUTES.CANCELLATION}/:kod`} element={<BookingCancellation />} />
          <Route path={ROUTES.BOOKED_MOBILE} element={<BookedMobile />} />
        </Routes>
      </Styled.ReservationContent>
      <Footer />
    </Styled.ReservationContainer>
  );
};

export default Reservation;
