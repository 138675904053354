import styled, { css } from "styled-components";

import { box, flexCenter, flexColumnCenter, flexColumnCenterHorizontal, font } from "@src/styles/mixins";

import { GAP_BETWEEN_SERVICES_CARD, GAP_BETWEEN_SERVICES_CARD_TABLET } from "../ServicesSection/styles";

const CARD_COUNT_IN_ROW = 3;
const CARD_COUNT_IN_ROW_TABLET = 2;

const ServicesTitle = styled.h3<{ isMainPage: boolean }>`
  flex-grow: 1;
  max-width: 250px;
  margin-bottom: 12px;
  ${font({ size: "20", lineHeight: "28", fontWeight: 700 })};
  text-align: center;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin-bottom: 28px;
    text-align: left;
    ${font({ size: "16", lineHeight: "24", fontWeight: "700" })};

    ${({ isMainPage }) =>
      !isMainPage &&
      css`
        margin-bottom: 12px;
      `}
  }
`;

const ServicesDetailText = styled.p<{ isMainPage: boolean }>`
  ${font({ size: "14", lineHeight: "22" })};
  color: ${({ theme }) => theme.color.grey};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    ${font({ size: "12", lineHeight: "20", fontWeight: "400" })};
    ${({ isMainPage }) =>
      !isMainPage &&
      css`
        display: none;
      `}
  }
`;

const ServicesDescription = styled.p`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 32px;
  width: calc(100% - 80px);
  ${font({ size: "16", lineHeight: "24" })};
  color: ${({ theme }) => theme.color.white};

  & a {
    ${font({ size: "16", lineHeight: "24" })};
    font-weight: 600;
    color: ${({ theme }) => theme.color.white};
    text-decoration: none;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    position: static;
    width: 100%;
    ${font({ size: "14", lineHeight: "22" })};
    color: ${({ theme }) => theme.color.black};
    transform: translateX(0);

    & a {
      ${font({ size: "14", lineHeight: "22" })};
      font-weight: 700;
      color: ${({ theme }) => theme.color.red};
      text-decoration: none;
    }
  }
`;

const ServicesImageWithHover = styled.img`
  display: none;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    display: block;

    object-fit: contain;
    object-position: center;
  }
`;

const ServicesImageNoHover = styled.img`
  display: block;

  object-fit: contain;
  object-position: center;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    display: none;
  }
`;

const AnimationWrapper = styled.div`
  width: 100%;
`;

const ServicesCard = styled.div<{ isHovered: boolean; isMainPage: boolean; isInView?: boolean }>`
  cursor: pointer;
  position: relative;
  ${flexColumnCenterHorizontal};
  width: calc(
    100% / ${CARD_COUNT_IN_ROW} - ((${GAP_BETWEEN_SERVICES_CARD}px * 2) / (${CARD_COUNT_IN_ROW}))
  ); // 32px это отступ между карточками (48px * 2) / на количество карточек
  height: 350px;
  padding: 75px 32px 40px;
  background-color: ${({ theme, isHovered }) => (isHovered ? theme.color.red : theme.color.white)};
  color: ${({ theme, isHovered }) => (isHovered ? theme.color.white : theme.color.black)};
  box-sizing: border-box;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    width: calc(
      100% / ${CARD_COUNT_IN_ROW_TABLET} - ((${GAP_BETWEEN_SERVICES_CARD_TABLET}px) / (${CARD_COUNT_IN_ROW_TABLET}))
    );
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    min-height: 252px;
    height: auto;
    height: 100%;
    padding: 40px 32px;

    background-color: ${({ theme }) => theme.color.white};
    color: ${({ theme }) => theme.color.black};

    ${({ isMainPage }) =>
      isMainPage &&
      css`
        align-items: center;
        padding: 40px 32px 28px;
      `}
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    ${({ isHovered }) =>
      isHovered
        ? css`
            ${ServicesTitle}, ${ServicesDetailText} {
              opacity: 0;
              visibility: hidden;
            }

            ${ServicesDescription} {
              opacity: 1;
              visibility: visible;
            }

            ${ServicesImageWithHover} {
              opacity: 0;
              visibility: hidden;
              display: none;
            }

            ${AnimationWrapper} {
              opacity: 1;
              visibility: visible;
              display: block;
            }
          `
        : css`
            ${ServicesTitle}, ${ServicesDetailText} {
              opacity: 1;
              visibility: visible;
            }

            ${ServicesDescription} {
              opacity: 0;
              visibility: hidden;
            }

            ${ServicesImageWithHover} {
              opacity: 1;
              visibility: visible;
              display: block;
            }

            ${AnimationWrapper} {
              opacity: 0;
              visibility: hidden;
              display: none;
            }
          `};
  }

  ${(props) =>
    props.isMainPage &&
    css`
      ${flexColumnCenter};

      ${ServicesDetailText} {
        display: block;
        opacity: 1;
        visibility: visible;
      }
      ${ServicesDescription} {
        display: none;
        opacity: 0;
        visibility: hidden;
      }
    `}
`;

const ServicesIconWrap = styled.div<{ isMainPage: boolean }>`
  ${box(120)}
  ${flexCenter}
  margin-bottom: 27px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    ${box(96)}
    margin-bottom: 34px;

    ${(props) =>
      !props.isMainPage &&
      css`
        ${box(72)}
        margin-bottom: 20px;
      `}
  }
`;

export const Styled = {
  ServicesCard,
  ServicesIconWrap,
  ServicesTitle,
  ServicesDetailText,
  ServicesDescription,
  ServicesImageWithHover,
  ServicesImageNoHover,
  AnimationWrapper,
};
