import ReactTooltip from "react-tooltip";
import styled, { css } from "styled-components";

import { ReactComponent as InfoIcon } from "@src/assets/images/icons/info.svg";
import { Button } from "@src/components";
import { flexSpaceBetween, font } from "@src/styles/mixins";

const RatesWrapper = styled.div`
  ${flexSpaceBetween};
  align-items: initial;
  border-bottom: 1px solid ${({ theme }) => theme.color.greyBorder};
  padding: 32px;
`;

const RatesDescriptionWrapper = styled.div`
  width: 400px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    width: 100%;
  }
`;

const Name = styled.h3`
  ${font({ size: "16", lineHeight: "24", fontWeight: "700" })};
  margin-bottom: 12px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    ${font({ size: "14", lineHeight: "24", fontWeight: "500" })};
    margin-bottom: 8px;
  }
`;

const Description = styled.div`
  ${font({ size: "14", lineHeight: "22", fontWeight: "300" })};
  display: flex;
  align-items: center;
  gap: 10px;
  color: ${({ theme }) => theme.color.black};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    ${font({ size: "13", lineHeight: "21", fontWeight: "300" })};
  }
`;

const Prices = styled.div`
  display: flex;
  align-items: center;
  gap: 13.5px;
`;

const PricesWrapper = styled.div`
  display: grid;
  justify-items: end;
  gap: 20px;
`;

const PricesButton = styled(Button)`
  width: 131px;
  height: 48px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    width: 110px;
  }
`;

const PerNight = styled.div`
  ${font({ size: "14", lineHeight: "22", fontWeight: "300" })};
  color: ${({ theme }) => theme.color.grey};
`;

const Price = styled.div`
  ${font({ size: "18", lineHeight: "26", fontWeight: "700" })};
  ${(props: { isSecond?: boolean }) =>
    css`
      color: ${({ theme }) => (!props.isSecond ? theme.color.red : theme.color.black)};
    `}
`;

const Tooltip = styled(ReactTooltip)`
  ${font({ size: "13", lineHeight: "16", fontWeight: "400" })};
  max-width: 358px;
  box-sizing: border-box;
  padding: 16px 20px;
`;
const Info = styled(InfoIcon)`
  &:hover {
    stroke: ${({ theme }) => theme.color.black};
    cursor: pointer;
  }
`;

export const Styled = {
  PerNight,
  Info,
  Tooltip,
  Price,
  PricesWrapper,
  Prices,
  PricesButton,
  RatesWrapper,
  RatesDescriptionWrapper,
  Name,
  Description,
};
