import styled, { css } from "styled-components";

import { font } from "@src/styles/mixins";

const Tag = styled.div`
  display: inline-flex;
  align-items: center;
  box-sizing: border-box;
  padding: 4px 10px;
  margin: 2px;
  height: 32px;
  background-color: ${({ theme }) => theme.color.lightGrey};
`;

const Icon = styled.img<{ small?: boolean }>`
  margin-right: 14px;
  min-width: 24px;
  width: 24px;
  height: 28px;
  object-fit: contain;
  object-position: center;

  ${({ small }) =>
    small &&
    css`
      height: 28px;
      width: 20px;
      min-width: 20px;
    `};
`;

const Text = styled.span`
  ${font({ size: "14", lineHeight: "22", fontWeight: "400" })};
  color: ${({ theme }) => theme.color.black};
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Styled = { Tag, Icon, Text };
