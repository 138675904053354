import styled, { css } from "styled-components";

import { box, flexCenter, flexColumnCenterHorizontal, flexColumnCenter } from "@src/styles/mixins";

const Container = styled.div`
  ${flexCenter}

  width: calc(100% - 40px);
  gap: 48px;

  max-width: 1400px;
  margin: 0 auto;
  margin-bottom: 66px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xlarge}) {
    max-width: 1184px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletLarge}) {
    width: calc(100% - 48px);
    gap: 12px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    width: calc(100% - 48px);
    flex-direction: column;
    gap: 12px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    width: calc(100% - 40px);
    margin: 0 20px 64px;
  }
`;

export const Styled = {
  Container,
};
