import { FC } from "react";

import { GuestInRateType, ManIcons, RatesToBlockType } from "@src/components";
import { useActions, useAppSelector, useGetBaseData } from "@src/hooks";
import { IPrice, IRate } from "@src/interfaces";
import { declOfNum } from "@src/utils";

import { Styled } from "./styles";
import { getSelectedRatesIDs } from "../../utils";

type RatesProps = {
  rate: IRate;
  onSetRateCounter: (rate: IRate, id: string, price: string, isCancel: boolean) => void;
  rateCounter: { [key: string]: number };
  isAllSelected: boolean;
  allRates: number;
  index: number;
  numberOfNights: number;
  hasFreeRooms: boolean;
  countNumberOfSelectedRooms: (value: number) => void;
  numberOfSelectedRooms: number;
  totalRoomAvailable?: string;
  roomId: number;
  ratesToBlock: RatesToBlockType[];
};
export const Rates: FC<RatesProps> = ({
  numberOfNights,
  index,
  allRates,
  rate,
  isAllSelected,
  onSetRateCounter,
  rateCounter,
  hasFreeRooms,
  countNumberOfSelectedRooms,
  numberOfSelectedRooms,
  totalRoomAvailable,
  roomId,
  ratesToBlock,
}) => {
  const {
    cancel_text: description,
    payment_text: paymentText,
    name,
    prices,
    help_text,
    loyalty,
    this_type_room_available,
  } = rate;

  const {
    chooseButtonText,
    cancelButton,
    alreadyChooseText,
    textNight,
    textNights,
    textNightsGenetiv,
    textSuggestionFor,
    currencyValue,
  } = useGetBaseData();
  const { tariffCounter, roomCounter } = useAppSelector((state) => state.bookingData);
  const { setTariffCounter, setRoomCounter } = useActions();

  const alreadyChoose = alreadyChooseText.replace("#rooms_count#", String(allRates));

  const nightsText = `${textSuggestionFor} ${numberOfNights} ${declOfNum(Number(numberOfNights), [
    textNight,
    textNights,
    textNightsGenetiv,
  ])}`;

  const isLoyalRoom = Number(loyalty) > 0;

  const handleAddCurrentRoom = () => {
    if (roomCounter[roomId]) {
      setRoomCounter({ ...roomCounter, [roomId]: roomCounter[roomId] + 1 });
    } else {
      setRoomCounter({ ...roomCounter, [roomId]: 1 });
    }
  };

  const resetNumberOfCurrentRoom = () => {
    setRoomCounter({ ...roomCounter, [roomId]: 0 });
  };

  const handleAddTariffRoom = (id: string) => {
    if (tariffCounter[id]) {
      setTariffCounter({ ...tariffCounter, [id]: tariffCounter[id] + 1 });
    } else {
      setTariffCounter({ ...tariffCounter, [id]: 1 });
    }
  };

  const resetNumberOfTariffRoom = (id: string) => {
    setTariffCounter({ ...tariffCounter, [id]: 0 });
  };

  const areRoomsOfTariffAvailable = (id: string, maxValue = this_type_room_available) => {
    if (tariffCounter[id] && maxValue) {
      return tariffCounter[id] < Number(maxValue);
    } else {
      return true;
    }
  };

  const isRoomAvailable = () => {
    if (roomCounter[roomId] && totalRoomAvailable) {
      return roomCounter[roomId] < Number(totalRoomAvailable);
    } else {
      return true;
    }
  };

  const handleRateToBlockCheck = (p: IPrice) => {
    const formattedPriceObj = {
      adult_count: p.adult_count,
      child_under_11_count: p.child_under_11_count,
      child_under_5_count: p.child_under_5_count,
    };

    const rateString = JSON.stringify(formattedPriceObj);
    const isRateToBlock = ratesToBlock.find((item) => {
      return item.id === rateString;
    });
    return !!isRateToBlock;
  };

  const selectedRatesIDsArray = getSelectedRatesIDs(rateCounter);

  return (
    <Styled.RatesWrapper>
      <Styled.RatesDescriptionWrapper>
        <Styled.Name>{name}</Styled.Name>
        <Styled.Description>
          {description || "Бесплатная отмена бронирования"}
          <Styled.Info data-for="descriptionRates" data-tip={help_text} data-date={help_text} />
          <Styled.Tooltip id="descriptionRates" />
        </Styled.Description>
        <Styled.Description>{paymentText || "Оплата банковской картой"}</Styled.Description>
      </Styled.RatesDescriptionWrapper>
      <Styled.PricesWrapper>
        {prices.map((p) => {
          const roomAmountArray = [roomCounter[roomId], tariffCounter[rate.id], rateCounter[p.id]];
          const counterText = Math.min(...roomAmountArray);

          const formatedPrice = String(p.total)
            .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
            .split(".")[0];

          const currentRate = p.id;

          const isCurrentRateChosen = !!selectedRatesIDsArray.find((item) => {
            return item === currentRate;
          });

          const isRateToBlock = handleRateToBlockCheck(p);
          const showDisabledBtnForChosenBlockedRate = isRateToBlock && isCurrentRateChosen;

          const showDisabledBtnForAnotherBlockedRate = isRateToBlock && !isCurrentRateChosen && !!rateCounter[p.id];
          const showCancelBtnForAnotherBlockedRate = isRateToBlock && !isCurrentRateChosen;
          const rateIsNotBlocked = !showDisabledBtnForChosenBlockedRate && !showCancelBtnForAnotherBlockedRate;

          const showAddBtn =
            !isAllSelected && isRoomAvailable() && areRoomsOfTariffAvailable(rate.id) && rateIsNotBlocked;
          const showdisabledBtn =
            ((isAllSelected || !isRoomAvailable() || !areRoomsOfTariffAvailable(rate.id)) && !rateCounter[p.id]) ||
            showCancelBtnForAnotherBlockedRate;

          const isAddedItemCouldBeCancelled = rateCounter[p.id]
            ? showDisabledBtnForChosenBlockedRate
            : showDisabledBtnForAnotherBlockedRate;

          const showCancelBtn =
            ((isAllSelected || !isRoomAvailable() || !areRoomsOfTariffAvailable(rate.id)) && !!rateCounter[p.id]) ||
            isAddedItemCouldBeCancelled;

          return (
            <Styled.Prices key={p.id}>
              <ManIcons price={p} color={!isLoyalRoom ? "black" : "#A0072B"} />
              <Styled.Price isSecond={!isLoyalRoom}>
                {formatedPrice} {currencyValue}
              </Styled.Price>
              <Styled.PerNight>{nightsText}</Styled.PerNight>
              {showAddBtn && (
                <Styled.PricesButton
                  onClick={() => {
                    handleAddCurrentRoom();
                    handleAddTariffRoom(rate.id);
                    onSetRateCounter(
                      rate,
                      p.id,
                      p.total,
                      (isAllSelected || !isRoomAvailable() || !areRoomsOfTariffAvailable(rate.id)) &&
                        !!rateCounter[p.id]
                    );
                  }}
                  width="131px"
                  counter={rateCounter[p.id]}
                  title={chooseButtonText}
                  primary
                />
              )}

              {showdisabledBtn && (
                <Styled.PricesButton
                  onClick={() => {
                    onSetRateCounter(rate, p.id, p.total, isAllSelected && !!rateCounter[p.id]);
                  }}
                  width="131px"
                  title={chooseButtonText}
                  isDisabled
                  data-for="pricesButtonRates"
                  data-tip={alreadyChoose}
                />
              )}
              {showCancelBtn && (
                <Styled.PricesButton
                  onClick={() => {
                    resetNumberOfCurrentRoom();
                    resetNumberOfTariffRoom(rate.id);
                    onSetRateCounter(
                      rate,
                      p.id,
                      p.total,
                      ((isAllSelected || !isRoomAvailable() || !areRoomsOfTariffAvailable(rate.id)) &&
                        !!rateCounter[p.id]) ||
                        showDisabledBtnForChosenBlockedRate
                    );
                  }}
                  width="131px"
                  counter={rateCounter[p.id]}
                  title={cancelButton}
                  outline
                />
              )}

              {isAllSelected && !rateCounter[p.id] && <Styled.Tooltip id="pricesButtonRates" />}
            </Styled.Prices>
          );
        })}
      </Styled.PricesWrapper>
    </Styled.RatesWrapper>
  );
};
