import { BlogDetailedArticleType, Image } from "@src/interfaces";
import { useGetBlogArticleByIdQuery } from "@src/store/services";

type ReturnedData = {
  // todo blog
  data?: BlogDetailedArticleType;
  isLoading?: boolean;
  id?: number;
  name: string;
  link: string;
  detailedName: string;
  category_name: string;
  img?: Image;
  imgTablet?: Image;
  imgMobile?: Image;
  description: string;
  metaTitle: string;
  metaDescription: string;
  metaKeywords: string;
  detailButtonTitle: string | null;
  detailButtonLink: string | null;
  formTitle: string | null;
  formButtonText: string | null;
  showCallForm: number;
};

export const useGetBlogArticleData = (slug: string | undefined): ReturnedData => {
  if (!slug) {
    return {
      name: "",
      link: "",
      detailedName: "",
      category_name: "",
      description: "",
      metaTitle: "",
      metaDescription: "",
      metaKeywords: "",
      detailButtonTitle: "",
      detailButtonLink: "",
      formTitle: "",
      formButtonText: "",
      showCallForm: 0,
    };
  }
  const { data, isLoading } = useGetBlogArticleByIdQuery(slug);

  const id = data && data?.id;
  const name = data ? data?.name : "";
  const link = data ? data?.slug : "";
  const detailedName = data ? data?.detail_name : "";
  const category_name = data ? data?.category_name : "";
  const img = data && data?.img;
  const imgTablet = data && data?.img_tablet;
  const imgMobile = data && data?.img_mobile;
  const description = data ? data?.description : "";
  const detailButtonTitle = data ? data?.detail_button_title : "";
  const detailButtonLink = data ? data?.detail_button_link : "";
  const formTitle = data ? data?.form_title : "";
  const formButtonText = data ? data?.form_button_text : "";
  const showCallForm = data ? data?.show_form : 0;

  const metaTitle = data ? data?.meta_title : "";
  const metaDescription = data ? data?.meta_description : "";
  const metaKeywords = data ? data?.meta_keywords : "";

  return {
    data,
    isLoading,
    id,
    name,
    link,
    detailedName,
    category_name,
    img,
    imgTablet,
    imgMobile,
    description,
    metaTitle,
    metaDescription,
    metaKeywords,
    detailButtonTitle,
    detailButtonLink,
    formTitle,
    formButtonText,
    showCallForm,
  };
};
