import styled, { css } from "styled-components";

import { flexStartBetween, font } from "@src/styles/mixins";

const ChooseRoomsCardContainer = styled.div``;

const ChooseRoomsCard = styled.div`
  position: relative;
  border: 1px solid ${({ theme }) => theme.color.greyBorder};
  width: calc(100% - 2px);
  display: flex;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletLarge}) {
    flex-direction: column;
    align-items: flex-start;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    flex-direction: column;
    align-items: center;
  }
`;

const TotalAmount = styled.span`
  position: absolute;
  top: 8px;
  left: 8px;
  padding: 2px 12px;
  background-color: ${({ theme }) => theme.color.black};
  ${font({ size: "12", lineHeight: "20", fontWeight: "400" })};
  color: ${({ theme }) => theme.color.white};
  border-radius: 2px;
`;

const DescriptionWrapper = styled.div`
  ${flexStartBetween};
  padding: 28px;
  flex-direction: column;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletLarge}) {
    padding: 32px;
  }
`;

const Description = styled.div`
  ${font({ size: "14", lineHeight: "22", fontWeight: "400" })};
  color: ${({ theme }) => theme.color.grey};
  margin-top: 10px;
  overflow: hidden;
  position: relative;
  height: 45px;
  ${(props: { isHidden: boolean }) =>
    props.isHidden &&
    css`
      overflow: initial;
      height: auto;
    `};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletLarge}) {
    display: none;
  }
`;

const MoreButton = styled.div`
  ${font({ size: "14", lineHeight: "22", fontWeight: "400" })};
  width: 55%;
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: ${({ theme }) => theme.color.white};
  display: flex;
  p {
    text-decoration: underline;
    margin-left: 5px;
    cursor: pointer;
  }
`;

const Name = styled.div`
  cursor: pointer;
  ${font({ size: "20", lineHeight: "28", fontWeight: "700" })};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletLarge}) {
    ${font({ size: "20", lineHeight: "26", fontWeight: "700" })};
    margin: 0 0 32px 0;
    max-width: 217px;
  }
`;

const Img = styled.img`
  width: 375px;
  height: 272px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletLarge}) {
    width: 100%;
    height: 244px;
  }
`;
const ImgContainer = styled.div`
  cursor: pointer;
  width: 100%;
  max-width: 375px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletLarge}) {
    max-width: unset;
    display: none;
  }

  & picture,
  & img {
    width: 375px;
    height: 272px;
    display: block;
    object-fit: cover;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletLarge}) {
      width: 100%;
      height: 244px;
    }
  }
`;
const ImgContainerMobile = styled.div`
  display: none;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletLarge}) {
    cursor: pointer;
    display: block;
    width: 100%;
  }

  & picture,
  & img {
    display: block;
    object-fit: cover;
    width: 100%;
    height: 244px;
  }
`;

const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const RatesWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.color.greyBorder};
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletLarge}) {
    display: none;
  }
`;

const MobileFooter = styled.div`
  display: none;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletLarge}) {
    box-sizing: border-box;
    display: block;
    border-right: 1px solid ${({ theme }) => theme.color.greyBorder};
    border-bottom: 1px solid ${({ theme }) => theme.color.greyBorder};
    border-left: 1px solid ${({ theme }) => theme.color.greyBorder};
    padding: 21px 28px;
  }
`;

const PriceWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 12px;
  margin-bottom: 12px;
`;

const SubTitle = styled.div`
  ${font({ size: "14", lineHeight: "22", fontWeight: "300" })};
  color: ${({ theme }) => theme.color.grey};
`;

const Price = styled.div`
  ${font({ size: "18", lineHeight: "26", fontWeight: "600" })};
  color: ${({ theme }) => theme.color.red};
`;

const SelectedRoomsContainer = styled.div`
  margin-top: 18px;
`;

export const Styled = {
  PriceWrapper,
  MoreButton,
  SubTitle,
  Price,
  MobileFooter,
  RatesWrapper,
  Tags,
  ChooseRoomsCard,
  Img,
  ImgContainer,
  ImgContainerMobile,
  DescriptionWrapper,
  Name,
  Description,
  TotalAmount,
  SelectedRoomsContainer,
  ChooseRoomsCardContainer,
};
