import styled, { css } from "styled-components";

import { ReactComponent as CloseIcon } from "@src/assets/images/icons/close.svg";
import { flexSpaceBetween, font, width100 } from "@src/styles/mixins";

const ModalContent = styled.div<{ size: "medium" | "large"; tabletFullWitdh?: boolean }>`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  height: auto;
  max-height: calc(100vh - 52px);
  width: ${({ size }) => (size === "medium" ? "600px" : "1028px")};
  background: ${({ theme }) => theme.color.white};
  padding: 28px 48px 40px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    position: relative;
    height: 100%;
    max-height: unset;
    width: 100vw;
    transform: none;
    top: 0;
    left: 0;
    right: 0;
    padding: 0;
    box-sizing: border-box;
  }

  ${({ tabletFullWitdh }) =>
    tabletFullWitdh &&
    css`
      @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletLarge}) {
        position: fixed;
        height: 100%;
        max-height: unset;
        width: 100vw;
        transform: none;
        top: 0;
        left: 0;
        right: 0;
        padding: 0;
        box-sizing: border-box;
      }
    `};
`;
const ModalHeader = styled.div`
  position: relative;
  ${flexSpaceBetween};
  ${width100};
  margin-bottom: 28px;
  ${font({ size: "20", lineHeight: "28", fontWeight: "700" })};
  color: ${({ theme }) => theme.color.black};
  box-sizing: border-box;
`;

const CloseButton = styled(CloseIcon)<{ isCloseBtnSticky?: boolean; tabletFullWitdh?: boolean }>`
  position: absolute;
  top: 4px;
  right: -17px;
  cursor: pointer;
  stroke: ${(props) => props.stroke};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    top: 22px;
    right: 22px;

    ${({ isCloseBtnSticky }) =>
      isCloseBtnSticky &&
      css`
        top: 11px;
        right: 10px;
        z-index: 5;
        padding: 10px;
        background-color: #ffffff;
      `};
  }

  ${({ tabletFullWitdh }) =>
    tabletFullWitdh &&
    css`
      @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletLarge}) {
        top: 22px;
        right: 22px;

        ${({ isCloseBtnSticky }) =>
          isCloseBtnSticky &&
          css`
            top: 11px;
            right: 10px;
            z-index: 5;
            padding: 10px;
            background-color: #ffffff;
          `};
      }
    `};
`;

const ModalBody = styled.div<{ hasFixedFooter?: boolean }>`
  width: 100%;
  max-height: calc(100vh - 173px);
  padding-bottom: 100px;
  box-sizing: border-box;

  overflow-x: hidden;
  overflow-y: scroll;
  display: flex;
  flex-direction: column-reverse;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    max-height: calc(100vh - 84px);

    padding-bottom: 30px;
    flex-direction: column;
  }

  ${({ hasFixedFooter }) =>
    !hasFixedFooter &&
    css`
      @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
        max-height: 100vh;
      }
    `};

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const ButtonSubmit = styled.div`
  position: fixed;
  padding-left: 48px;
  padding-bottom: 41px;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${({ theme }) => theme.color.white};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    ${width100};
    position: absolute;
    bottom: 0;
    border-top: 1px solid ${({ theme }) => theme.color.greyBorderOpacity50};
    padding: 8px;
    box-sizing: border-box;
  }
`;

export const Styled = { ButtonSubmit, ModalBody, ModalHeader, ModalContent, CloseButton };
