import { useLocation } from "react-router-dom";

import { useGetBaseDataQuery } from "@src/store/services";

type ReturnedData = {
  links: {
    text: string;
    url: string;
  }[];
  isFetching: boolean;
  mainText: string;
  servicesNavLink: string;
  longStayTitle: string;
  longStayNavLink: string;
  longStayBreadcrumps: string;
  phoneNumberText: string;
  bookingTitle: string;
  requestCallText: string;
  copyRightText: string;
  textToChangeLanguage: string;
  languageId?: number;
  languageName: string;
  languageIcon: string;
  addressValue: string;
  socialNetworksText: string;
  bookBtnPhrase: string;
  bookGuests: string;
  bookArrivalDate: string;
  bookDepartureDate: string;
  moreInfoButtonText: string;
  requestMarkPhrase: string;
  fractionTitlePhoto: string;
  roomsServicesTitle: string;
  roomsServicesLongStayTitle: string;
  roomDescriptionText: string;
  contactsText: string;
  weddingsBreadcrumb: string;
  infrastructureBreadcrumpText: string;
  actionsBreadcrumbsText: string;
  eventsBreadcrump: string;
  blogBreadcrumps: string;
};

export const useGetMenuData = (): ReturnedData => {
  // change lang
  const location = useLocation();
  const locationToCheck = location.pathname.split("/");
  const isEnglishVersion = locationToCheck.indexOf("en") > 0;

  const { data, isFetching } = useGetBaseDataQuery();
  const servicesNavLink = data ? data?.lang_phrases.nav_services : "";

  const linksRussian = [
    { text: data ? data?.lang_phrases.nav_booking : "", url: "/reservation/guests-counter" },
    { text: data ? data?.lang_phrases.actions_navigation : "", url: "/promo" },
    { text: data ? data?.lang_phrases.nav_services : "", url: "/rooms-and-services" },
    { text: data ? data?.lang_phrases.nav_long_stay : "", url: "/long-stay" },
    { text: data ? data?.lang_phrases.menu_spa : "", url: "/spa" },
    { text: data ? data?.lang_phrases.menu_event : "", url: "/events" },
    { text: data ? data?.lang_phrases.menu_weddings : "", url: "/weddings" },
    { text: data ? data?.lang_phrases.menu_infrastructure : "", url: "/infrastructure" },
    { text: data ? data?.lang_phrases.nav_contacts : "", url: "/contacts" },

    { text: data ? "Блог" : "", url: "/blog" },
  ];
  const linksEnglish = [
    { text: data ? data?.lang_phrases.nav_booking : "", url: "/en/reservation/guests-counter" },
    { text: data ? data?.lang_phrases.actions_navigation : "", url: "/en/promo" },
    { text: data ? data?.lang_phrases.nav_services : "", url: "/en/rooms-and-services" },
    { text: data ? data?.lang_phrases.nav_long_stay : "", url: "/en/long-stay" },
    { text: data ? data?.lang_phrases.menu_spa : "", url: "/en/spa" },
    { text: data ? data?.lang_phrases.menu_event : "", url: "/en/events" },
    { text: data ? data?.lang_phrases.menu_weddings : "", url: "/en/weddings" },
    { text: data ? data?.lang_phrases.menu_infrastructure : "", url: "/en/infrastructure" },
    { text: data ? data?.lang_phrases.nav_contacts : "", url: "/en/contacts" },

    { text: data ? "Blog" : "", url: "/en/blog" },
  ];

  const links = !isEnglishVersion ? linksRussian : linksEnglish;

  const mainText = data ? data?.lang_phrases.text_main : "";
  const requestCallText = data ? data?.lang_phrases.request_call : "";
  const phoneNumberText = data ? data?.lang_phrases.site_phone : "";
  const copyRightText = data ? data?.lang_phrases.copyright_text : "";
  const addressValue = data ? data?.lang_phrases.hotel_address : "";
  const socialNetworksText = data ? data?.lang_phrases.social_networks : "";
  const bookBtnPhrase = data ? data?.lang_phrases.booking_text : "";
  const bookGuests = data ? data?.lang_phrases.booking_guests : "";
  const bookArrivalDate = data ? data?.lang_phrases.arrival_date : "";
  const bookDepartureDate = data ? data?.lang_phrases.departure_date : "";
  const moreInfoButtonText = data ? data?.lang_phrases.more : "";
  const requestMarkPhrase = data ? data?.lang_phrases.on_request : "";
  const fractionTitlePhoto = data ? data?.lang_phrases.photo_text : "";
  const roomsServicesTitle = data ? data?.lang_phrases.services : "";
  const roomsServicesLongStayTitle = data ? data?.lang_phrases.long_stay_title : "";
  const roomDescriptionText = data ? data?.lang_phrases.description_room : "";
  const contactsText = data ? data?.lang_phrases.contacts : "";
  const bookingTitle = data ? data?.lang_phrases.booking_title : "";
  const weddingsBreadcrumb = data ? data?.lang_phrases.wedding_breadcrumb : "";
  const infrastructureBreadcrumpText = data ? data?.lang_phrases.breadcrumbs_infrastructure : "";
  const actionsBreadcrumbsText = data ? data?.lang_phrases.actions_breadcrumbs : "";
  const blogBreadcrumbsText = data ? data?.lang_phrases.blog_breadcrumbs : "";

  const textToChangeLanguage = data ? data?.languages.others[0].footer_text : "";
  const languageId = data && data?.languages.others[0].id;
  const languageName = data ? data?.languages.others[0].name : "";
  const languageIcon = data ? data?.languages.others[0].icon : "";

  const eventsBreadcrump = data ? data?.lang_phrases.event_breadcrumbs_text : "";

  const longStayTitle = data ? data?.lang_phrases.long_stay_title : "";
  const longStayNavLink = data ? data?.lang_phrases.nav_long_stay : "";
  const longStayBreadcrumps = data ? data?.lang_phrases.long_stay_breadcrumbs : "";
  const blogBreadcrumps = data ? data?.lang_phrases.blog_breadcrumbs : "";

  return {
    links,
    contactsText,
    bookingTitle,
    isFetching,
    mainText,
    servicesNavLink,
    longStayTitle,
    longStayNavLink,
    longStayBreadcrumps,
    phoneNumberText,
    requestCallText,
    copyRightText,
    textToChangeLanguage,
    languageId,
    languageName,
    languageIcon,
    addressValue,
    socialNetworksText,
    bookBtnPhrase,
    bookGuests,
    bookArrivalDate,
    bookDepartureDate,
    moreInfoButtonText,
    requestMarkPhrase,
    fractionTitlePhoto,
    roomsServicesTitle,
    roomsServicesLongStayTitle,
    roomDescriptionText,
    weddingsBreadcrumb,
    infrastructureBreadcrumpText,
    actionsBreadcrumbsText,
    eventsBreadcrump,
    blogBreadcrumps,
  };
};
